<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="restore">
                  <h1>Restablecer contraseña</h1>
                  <p class="text-muted">Ingresa tu nueva contraseña</p>
                  <CInput
                    type="password"
                    placeholder="Contraseña"
                    label="Contraseña"
                    v-model="form.password"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="key"
                    /></template>
                  </CInput>
                  <CInput
                    type="password"
                    placeholder="Confirma tu contraseña"
                    label="Confirma tu contraseña"
                    v-model="form.password_confirmation"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="key"
                    /></template>
                  </CInput>
                  <CRow v-if="alert.show">
                    <CCol>
                      <CAlert :color="alert.color" dismissible>
                        <strong>{{ alert.message }}</strong>
                      </CAlert>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton color="primary" class="px-8" type="submit">
                        <CSpinner v-if="loading" variant="grow" size="sm"/>
                         Cambiar contraseña  
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody class="banner">
                <img src="img/banner_inicio.jpg" width="100%" />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { resetPassword } from '@/api/auth'


export default {
  data: () => ({
    user: {},
    form: {
      password: '',
      password_confirmation: '',
    },
    alert: {
      color: 'danger',
      message: '',
      show: false,
    },
    loading: false,
    sended: false,
  }),
  mounted() {
    if(this.$store.state.user.token){
      this.$router.push('/commerceInventory');
    }
  },
  methods: {
    async restore() {
      this.form.token = this.$route.params.token;
      this.form.email = this.$route.params.email;
      this.loading = true;
      this.alert.show = false;
      await resetPassword(this.form).then(response => {
        this.$store.commit('setToken', response.data.token);
        this.$cookies.set('login_token', response.data.token);
        if(response.data.token){
          this.$store.commit('setMenu', response.data.menu);
          this.$store.commit('setRole', response.data.user.role);
          this.$store.commit('setName', response.data.user.name);
        }
        location.reload();
        this.loading = false;
      }).catch(error => {
        this.alert.color = 'danger';
        this.alert.show = true;
        if(error.response.data.password) this.alert.message = error.response.data.password[0];
        if(error.response.data.email) this.alert.message = error.response.data.email[0];
        if(error.response.data.token) this.alert.message = error.response.data.token[0];
        this.loading = false;
      })
    },
    open() {
      this.$router.push({
        path: "login",
      });
    },
  },
  name: 'Login'
}
</script>
